import app from "js/legacy-app";
import UAParser from "ua-parser-js";

app.filter("userAgent", [function () {
    return function (userAgent) {
        var agent = UAParser(userAgent);
        return agent.os.name +
            (agent.os.version ? (' ' + agent.os.version) + ', ' : ', ') +
            agent.browser.name + ' ' + agent.browser.version;
    };
}]);