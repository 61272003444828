import "./input-stars.component.scss";

export default {
  template: require('./input-stars.component.html'),
  transclude: true,
  bindings: {
    max: "=?vuiMax",
    ngModel: "=ngModel"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.current = self.ngModel || self.max;
    self.stars = [];

    for (let i = 0; i < self.max; i++) {
      self.stars.push(i + 1);
    }

    self.select = value => {
      self.current = self.ngModel = value;
    }

    self.mouseover = value => {
      self.current = value;
    }

    self.mouseleave = () => {
      self.current = self.ngModel || self.max;
    }

  }
};
