import './confirmation.modal.scss';

export default function ConfirmationModal ($scope, close, message) {
  'ngInject';

  $scope.message = message;

  $scope.ok = () => {
    close(true);
  }

  $scope.close = () => {
    close(false);
  }
}