export default function LocationServiceProvider() {
  this.$get = () => {
    'ngInject';

    function LocationService() {

      const self = this;

      self.serializeAddress = (address) => {
        if (!address) {
          return undefined;
        }

        let result = "";
        result += (address.address ? address.address : "") + ";";
        result += (address.zip ? address.zip : "") + ";";
        result += (address.city ? address.city : "") + ";";
        result += (address.state ? address.state : "") + ";";
        result += (address.country ? address.country : "") + ";";
        result += address.longitude + ";";
        result += address.latitude + ";";
        result += (address.bounds ? address.bounds.south : "") + ";";
        result += (address.bounds ? address.bounds.west : "") + ";";
        result += (address.bounds ? address.bounds.north : "") + ";";
        result += (address.bounds ? address.bounds.east : "");

        return result;
      }

      self.deserializeAddress = (string) => {
        if (!string) {
          return undefined;
        }

        let parts = string.split(';'), result = {};

        if (parts.length !== 11) {
          return undefined;
        }

        result.address = parts[0];
        result.zip = parts[1];
        result.city = parts[2];
        result.state = parts[3];
        result.country = parts[4];
        result.longitude = parts[5];
        result.latitude = parts[6];

        if (parts[7] && parts[8] && parts[9] && parts[10]) {
          result.bounds = {};
          result.bounds.south = parts[7];
          result.bounds.west = parts[8];
          result.bounds.north = parts[9];
          result.bounds.east = parts[10];
        }

        return result;
      }

    }

    return new LocationService();
  };
}