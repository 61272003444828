import './side-menu.component.scss';

export default {
  template: require('./side-menu.component.html'),
  transclude: true,
  bindings: {
    onResizeMenu: "=vuiResize",
    onClose: "=vuiClose"
  },
  controller: ($scope, $document, $window, vuiResolutionService) => {
    'ngInject';

    $scope.menu = {};

    const onResize = () => {
      const y = window.scrollY;
      const headerSize = 84;
      let diff = headerSize - y;

      if (vuiResolutionService.isFullScreen()) {
        if (diff <= 0) {
          diff = 0;
        }
      } else {
        diff = 0;
      }

      $scope.menu.marginTop = diff + 'px';
      $scope.menu.height = 'calc(100dvh - ' + diff + 'px)';
    }

    const onScroll = (e) => {
      $scope.$apply(onResize)
    }

    $document.on('scroll', onScroll);
    $document.on('resize', onScroll);
    angular.element($window).bind('resize', onScroll);
    $document.on('$destroy', () => {
      $document.off('scroll', onScroll);
      $document.off('resize', onScroll);
      angular.element($window).unbind('resize', onScroll);
    });

    onResize();
  }
};
