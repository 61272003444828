import './side-menu-sign-out-item.component.scss';

export default {
  template: require('./side-menu-sign-out-item.component.html'),
  bindings: {
    href: "@?vuiHref",
    segment: "@?vuiSegment"
  },
  transclude: true,
  controller: function (AuthService) {
    'ngInject';

    this.signOut = AuthService.logout
  }
};
