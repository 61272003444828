import './breadcrumb.component.scss';

export default {
  template: require('./breadcrumb.component.html'),
  transclude: true,
  controller: function ($routeSegment) {
    'ngInject';

    this.getBreadcrumb = () => {
      return $routeSegment.chain.filter(segment => segment.params && segment.params.breadcrumb)
        .map(segment => segment.params.breadcrumb);
    }
  }
};
