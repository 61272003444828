import "./collapsible-section.component.scss";
import angular from "angular";

export default {
  template: require('./collapsible-section.component.html'),
  bindings: {
    expand: "=?vuiExpand"
  },
  transclude: {
    title: "?vuiCollapsibleSectionTitle",
    content: "?vuiCollapsibleSectionContent"
  },
  controller: function ($element, $document, $window) {

    'ngInject';

    const self = this;

    const calculateHeight = () => {
      const container = $element.children().children()[1];
      const content = container.children[0];

      if (self.expand) {
        const height = content.clientHeight;
        container.style.height = height + 'px';
      } else {
        container.style.height = '';
      }
    }

    self.toggle = () => {
      self.expand = !self.expand;
      calculateHeight();
    }

    if (!self.expand) {
      self.expand = false;
    }
    calculateHeight();

    $document.on('resize', calculateHeight);
    angular.element($window).bind('resize', calculateHeight);
    $document.on('$destroy', () => {
      $document.off('resize', calculateHeight);
      angular.element($window).unbind('resize', calculateHeight);
    });
  }
};
