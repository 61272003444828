import angular from "angular";

import "oclazyload";
import BicycleOwnerBicyclesModule from "./modules/bicycles/bicycle-owner-bicycles.module";
import BicycleOwnerNotificationsModule from "./modules/notifications/bicycle-owner-notifications.module";
import BicycleOwnerProfileModule from "./modules/profile/bicycle-owner-profile.module";
import BicycleOwnerRequestsModule from "./modules/requests/bicycle-owner-requests.module";
import BicycleOwnerRootModule from "./modules/root/bicycle-owner-root.module";
import BicycleOwnerServiceProvidersModule from "./modules/service-providers/bicycle-owner-service-providers.module";
import BicycleOwnerSettingsModule from "./modules/settings/bicycle-owner-settings.module";

export default angular
  .module("vt-bicycle-owner.module", [
    BicycleOwnerRootModule.name,
    BicycleOwnerBicyclesModule.name,
    BicycleOwnerNotificationsModule.name,
    BicycleOwnerProfileModule.name,
    BicycleOwnerRequestsModule.name,
    BicycleOwnerServiceProvidersModule.name,
    BicycleOwnerSettingsModule.name
  ]);
