import angular from "angular";
import moment from "moment";

import './date-picker.component.scss';

export default ($t) => {
  'ngInject';

  return {
    restrict: 'E',
    replace: true,
    require: ['ngModel'],
    scope: {
      ngModel: "=ngModel",
      placeholder: "@vuiPlaceholder",
      name: "@vuiName",
      required: "@vuiRequired",
      format: "@vuiFormat",
      minDate: "<vuiMinDate",
      maxDate: "<vuiMaxDate",
      disabled: "@disabled",
      clazz: "@ngClass",
      onChange: '=vuiOnChange',
      clear: '@vuiClear'
    },
    transclude: true,
    template: require('./date-picker.component.html'),
    link: (scope, element) => {

      const MONTHS = [
        $t('shared.months.jan'),
        $t('shared.months.feb'),
        $t('shared.months.mar'),
        $t('shared.months.apr'),
        $t('shared.months.may'),
        $t('shared.months.jun'),
        $t('shared.months.jul'),
        $t('shared.months.aug'),
        $t('shared.months.sept'),
        $t('shared.months.oct'),
        $t('shared.months.nov'),
        $t('shared.months.dec')
      ];

      const findElemByClass = (className) => {
        const list = element.find('div')
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            return angular.element(list[i]);
          }
        }
      }

      const findElemsByClass = (className) => {
        const list = element.find('div')
        const result = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            result.push(angular.element(list[i]));
          }
        }

        return result;
      }

      const dropdown = findElemByClass('vui-date-picker__dropdown');

      const open = () => {
        scope.changed = false;
        element.addClass('vui-input-container--opened');
        const rect = element[0].getClientRects()[0];

        const marginTop = calculateDropdownMargin(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
        dropdown.css('margin-top', marginTop + 'px');
        dropdown.css('width', rect.width + 'px');

        const activeItems = findElemsByClass('active');
        for (const item of activeItems) {
          item[0].scrollIntoView({block: "nearest", inline: "nearest"});
        }
      }

      const calculateDropdownMargin = (isBottom, dropdown) => {
        return isBottom ? -(dropdown.offsetHeight + element[0].offsetHeight + 8) : 8;
      }

      scope.showClearBtn = scope.clear === 'true';

      scope.setModel = (model) => {
        scope.ngModel = model;
        if (scope.onChange) {
          scope.onChange(scope.ngModel);
        }
        scope.close();
      }

      scope.toggle = () => {
        if (element.hasClass('vui-input-container--opened')) {
          scope.close();
        } else {
          open();
        }
      }

      scope.close = () => {
        element.removeClass('vui-input-container--opened')
        element.focus();

        if (scope.changed && scope.onChange) {
          scope.onChange(scope.ngModel);
        }
      }

      const $input = element.find('input');
      $input.on('focusin', (e) => scope.toggle());
      $input.on('keydown', function (e) {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 40) {
          dropdown.find('div')[0].focus();
          e.preventDefault();
        } else if (keyCode === 38) {
          const options = dropdown.find('div');
          options[options.length - 1].focus();
          e.preventDefault();
        } else if (keyCode === 27) {
          scope.close();
        } else {
          e.preventDefault();
        }
      });

      const init = (date) => {
        let minDate = scope.minDate;
        if (!minDate) {
          const date = new Date();
          date.setFullYear(1900);
          date.setMonth(0);
          date.setDate(1);
          minDate = date;
        }

        let maxDate = scope.maxDate;
        if (!maxDate) {
          const date = new Date();
          date.setFullYear(2100);
          date.setMonth(0);
          date.setDate(1);
          maxDate = date;
        }

        scope.dp = {
          days: [],
          months: [],
          years: [],
          currentDate: {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear()
          },
          format: scope.format ? scope.format : "DD MMM yyyy",
          minDate: minDate,
          maxDate: maxDate,
        }

        for (let i = scope.dp.minDate.getFullYear(); i <= scope.dp.maxDate.getFullYear(); i++) {
          scope.dp.years.push(i);
        }

        recalculatePicker();
      }

      const recalculatePicker = () => {
        if (scope.dp.currentDate.year === scope.dp.maxDate.getFullYear()) {
          if (scope.dp.maxDate.getMonth() + 1 !== scope.dp.months.length) {
            recalculateMonths(0, scope.dp.maxDate.getMonth())
          }

          const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
          if (scope.dp.currentDate.month >= scope.dp.maxDate.getMonth()) {
            scope.dp.currentDate.month = scope.dp.maxDate.getMonth();
            if (daysInMonth !== scope.dp.days.length) {
              recalculateDays(1, scope.dp.maxDate.getDate());
            }
          } else {
            const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
            if (daysInMonth !== scope.dp.days.length) {
              recalculateDays(1, daysInMonth);
            }
          }
        } else if (scope.dp.currentDate.year === scope.dp.minDate.getFullYear()) {
          if (scope.dp.minDate.getMonth() + 1 !== scope.dp.months.length) {
            recalculateMonths(scope.dp.minDate.getMonth(), 11)
          }

          const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
          if (scope.dp.currentDate.month <= scope.dp.minDate.getMonth()) {
            scope.dp.currentDate.month = scope.dp.minDate.getMonth();
            if (daysInMonth !== scope.dp.days.length) {
              recalculateDays(scope.dp.minDate.getDate(), daysInMonth);
            }
          } else {
            const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
            if (daysInMonth !== scope.dp.days.length) {
              recalculateDays(1, daysInMonth);
            }
          }
        } else {
          if (scope.dp.months.length !== MONTHS.length) {
            recalculateMonths(0, 11);
          }

          const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
          if (daysInMonth !== scope.dp.days.length) {
            recalculateDays(1, daysInMonth);
          }
        }
      }

      const recalculateMonths = (from, to) => {
        const months = []
        for (let i = from; i <= to; i++) {
          months.push({number: i, label: MONTHS[i]});
        }

        scope.dp.months = months;
      }

      const recalculateDays = (from, to) => {
        const days = []
        for (let i = from; i <= to; i++) {
          days.push(i);
        }

        scope.dp.days = days;
      }

      const recalculateDaysIfNeed = () => {
        const daysInMonth = new Date(scope.dp.currentDate.year, scope.dp.currentDate.month + 1, 0).getDate();
        if (daysInMonth < scope.dp.currentDate.day) {
          scope.dp.currentDate.day = daysInMonth;
        }

        recalculatePicker();
      }

      scope.selectDay = (day) => {
        scope.dp.currentDate.day = day;
        scope.onChangeDate();
      }

      scope.selectMonth = (month) => {
        scope.dp.currentDate.month = month;
        recalculateDaysIfNeed();
        scope.onChangeDate();
      }

      scope.selectYear = (year) => {
        scope.dp.currentDate.year = year;
        recalculateDaysIfNeed();
        scope.onChangeDate();
      }

      scope.onChangeDate = () => {
        const date = new Date();
        date.setDate(scope.dp.currentDate.day);
        date.setMonth(scope.dp.currentDate.month);
        date.setFullYear(scope.dp.currentDate.year);
        scope.ngModel = date;
        scope.changed = true;
      }

      scope.clearDate = () => {
        scope.ngModel = null;
        scope.changed = true;
        scope.close();
      }

      const updateValue = () => {
        if (scope.ngModel) {
          scope.dateStr = moment(scope.ngModel).format(scope.dp.format);
        } else {
          scope.dateStr = undefined;
        }
      }

      const updateCurrentDate = () => {
        if (scope.ngModel) {
          scope.dp.currentDate = {
            day: scope.ngModel.getDate(),
            month: scope.ngModel.getMonth(),
            year: scope.ngModel.getFullYear()
          }
        }
      }

      scope.$watch('ngModel', (newValue, oldValue) => {
        const isEqual = oldValue === newValue || (oldValue && newValue && oldValue.getDate() === newValue.getDate()
          && oldValue.getMonth() === newValue.getMonth() && oldValue.getFullYear() === newValue.getFullYear());
        if (!isEqual) {
          updateCurrentDate()
        }

        updateValue();
      })

      init(scope.ngModel ? scope.ngModel : new Date());
    }
  };
}
