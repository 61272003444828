import angular from "angular";

export default ($routeSegmentProvider, $routeProvider, $locationProvider) => {
  'ngInject';

  // enable html5 mode
  $locationProvider.html5Mode(true).hashPrefix('!');

  $routeSegmentProvider.options.autoLoadTemplates = true;

  const loadSegment = (source, origin) => {
    return (name, params) => {
      resolveTemplate(params);
      params.resolve = params.resolve || {};
      params.resolveFailed = {};
      angular.extend(params.resolve, {
        auth: ['accessCheckingService', (accessCheckingService) => {
          return accessCheckingService.check(params.access);
        }]
      });

      return origin.call(source, name, params);
    }
  }

  const loadWithinSegment = (source, origin) => {
    return (name) => {
      const result = origin.call(source, name);
      result.segment = loadSegment(result, result.segment);
      return result;
    }
  }

  $routeSegmentProvider.segment = loadSegment($routeSegmentProvider, $routeSegmentProvider.segment);
  $routeSegmentProvider.within = loadWithinSegment($routeSegmentProvider, $routeSegmentProvider.within)

  const resolveTemplate = (params) => {
    if (angular.isFunction(params.module)) {
      params.template = ($ocLazyLoad, $q) => {
        const deferred = $q.defer();
        params.module()
          .then(mod => {
            $ocLazyLoad.load(mod.default.module);
            deferred.resolve(mod.default.template);
          })
          .catch(err => {
            deferred.reject(err);
          });
        return deferred.promise;
      };
      params.template.$inject = ['$ocLazyLoad', '$q'];
    } else if (angular.isFunction(params.loadModule)) {
      const template = params.template;
      params.template = ($ocLazyLoad, $q) => {
        const deferred = $q.defer();
        params.loadModule()
          .then(mod => {
            $ocLazyLoad.load(mod.default);
            deferred.resolve(template);
          })
          .catch(err => {
            deferred.reject(err);
          });
        return deferred.promise;
      };
      params.template.$inject = ['$ocLazyLoad', '$q'];
    }
  }
}

