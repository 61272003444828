export default function ChatServiceProvider() {
  this.$get = ($filter, vuiChatWsService, AuthService) => {
    'ngInject';

    function Chat(chatId, requestId) {

      const MESSAGES_PAGE_SIZE = 10;

      const self = this;
      const handlers = [];

      self.chat = {
        id: chatId,
        requestId: requestId,
        messages: [],
        online: false,
        hasNext: true
      };
      self.ws = vuiChatWsService.init(self.chat.id);
      self.connect = self.ws.connect;
      self.close = self.ws.close;

      self.registerHandler = (id, fn) => {
        handlers.push({id: id, call: fn});
      }

      self.unregisterHandler = (id) => {
        for (let i = 0; i < handlers.length; i++) {
          if (handlers[i].id === id) {
            handlers.splice(i, 1);
          }
        }
      };

      self.getChat = () => structuredClone(self.chat)

      self.loadMoreMessages = () => {
        if (self.chat.hasNext) {
          const now = new Date().getTime();
          self.ws.send({
            operation: self.ws.LOAD_MORE_CODE,
            updatedAt: now,
            requestId: self.chat.requestId,
            negotiationId: self.chat.id,
            token: AuthService.getToken().access_token,
            lastCommentId: self.chat.messages[0] ? self.chat.messages[0].id : null,
            page: 0,
            size: MESSAGES_PAGE_SIZE
          });
        }
      };

      self.readLastMessage = () => {
        self.ws.send({
          operation: self.ws.READ_LAST_MESSAGE,
          requestId: self.chat.requestId,
          negotiationId: self.chat.id,
          token: AuthService.getToken().access_token
        });
      };

      self.sendMessage = (text, images) => {
        if (!text && !images) {
          return;
        }

        let comment = {
          operation: self.ws.NEW_MESSAGE_CODE,
          token: AuthService.getToken().access_token,
          requestId: self.chat.requestId,
          negotiationId: self.chat.id,
          updatedAt: new Date().getTime()
        };

        if (text) {
          const textWithoutEmoji = $filter("vuiClearEmoji")(text);
          comment.text = $filter("vuiXss")(textWithoutEmoji)
        }

        if (images) {
          comment.images = images;
        }

        self.ws.send(comment);
      };

      const changeInetConnectionStatus = connected => {
        self.chat.online = connected;
        callHandlers(false);
      };

      const connectionStatus = status => {
        changeInetConnectionStatus(status.data);
      };

      const receiveMessage = message => {
        self.chat.messages.push(message.data);
        callHandlers(true);
      };

      const receiveAllMessages = message => {
        self.chat.hasNext = message.data.next;
        message.data.data.sort((m1, m2) => m2.createdAt - m1.createdAt);
        const list = message.data.data;
        for (let i = 0; i < list.length; i++) {
          self.chat.messages.unshift(list[i]);
        }

        callHandlers(false);
      };

      const onRegister = message => {
        if (self.chat.messages.length === 0) {
          self.loadMoreMessages();
        }
      }

      const callHandlers = showLast => {
        handlers.forEach(handler => handler.call(self.chat, showLast));
      }

      self.ws.registerHandler('REGISTRATION', self.chat.id + '-registration', onRegister);
      self.ws.registerHandler('NEW', self.chat.id + '-new', receiveMessage);
      self.ws.registerHandler('LIST', self.chat.id + '-list', receiveAllMessages);
      self.ws.registerHandler('CONNECTION_STATUS', self.chat.id + '-connection-status', connectionStatus);
      self.ws.registerInternetConnectionHandler(changeInetConnectionStatus);
    }

    function ChatService() {
      const self = this;

      self.init = (chatId, requestId) => {
        const chat = new Chat(chatId, requestId);
        chat.connect();
        return chat;
      }
    }

    return new ChatService();
  };
}