export default ($resource) => {
  'ngInject';

  return $resource('/api/v1/fo/security/:path/:action', {}, {
    changePassword: {
      method: "POST",
      params: {path: "password"},
      isArray: false
    },
    sessions: {
      method: "GET",
      params: {path: "sessions"},
      isArray: true
    },
    closeSessions: {
      method: "POST",
      params: {
        path: "sessions",
        action: "close"
      },
      isArray: false
    },
  });
};
