import angular from "angular";

const services = function ($resource) {
    'ngInject';

    return $resource('/api/v1/public/services/:id', {}, {})
}

export default angular.module('vt-public-resource.services', [])
    .service('ServicesPublicApi', services);
