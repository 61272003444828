import "./infinite-scroll.component.scss";

export default {
  template: require('./infinite-scroll.component.html'),
  bindings: {
    onLoad: '=vuiOnLoad'
  },
  controller: function ($element, $document, $window, $scope, vuiResolutionService) {
    'ngInject';

    const self = this;

    const $list = $element.parent()[0];
    const onScroll = e => {
      const child = $element.parent().children()[0];

      if (vuiResolutionService.isFullScreen()) {
        const heightToLoad = child.offsetHeight * 2;

        const toEnd = $list.scrollHeight - ($list.scrollTop + $list.offsetHeight);
        if (toEnd < heightToLoad) {
          self.onLoad();
        }
      } else {
        const widthToLoad = child.offsetWidth * 2;

        const toEnd = $list.scrollWidth - ($list.scrollLeft + $list.offsetWidth);
        if (toEnd < widthToLoad) {
          self.onLoad();
        }
      }
    };

    $element.parent().on('scroll', onScroll);
    $element.parent().parent().on('scroll', onScroll);
    $element.parent().parent().parent().on('scroll', onScroll);
    $document.on('resize', onScroll);
    angular.element($window).bind('resize', onScroll);

    $scope.$on('$destroy', () => {
      $element.parent().off('scroll', onScroll);
      $element.parent().parent().parent().off('scroll', onScroll);
      $document.off('resize', onScroll);
      angular.element($window).unbind('resize', onScroll);
    })
  }
};
