import angular from "angular";

export default ($timeout, Payment) => {

  'ngInject';

  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      const setValue = (element, value, scope, ctrl) => {
        element.val(value);
        ctrl.$setViewValue(value);

        $timeout(() => {
          element.val(value);
          scope.$apply();
        });
      };

      const restrictCvc = (e) => {
        const element = angular.element(e.currentTarget || e.srcElement);
        let value = element.val();

        if (!value || value === ' ') {
          setValue(element, value, scope, ctrl);
          return;
        }

        value = value.substr(0, 4);

        setValue(element, value, scope, ctrl);
        e.preventDefault();
      }

      const validate = value => {
        if (value) {
          const valid = value ? Payment.validateCardCvc(value) : false;
          ctrl.$setValidity('cvc', valid);
          return valid;
        } else {
          ctrl.$setValidity('required', false);
          return false;
        }

      }

      ctrl.$parsers.unshift(value => {
        return validate(value) ? value : undefined;
      });

      ctrl.$formatters.unshift(value => {
        validate(value);
      });

      element.on('input', restrictCvc);
      element.on('paste', restrictCvc);

      scope.$on('$destroy', () => {
        element.off('input', restrictCvc);
        element.off('paste', restrictCvc);
      });
    }
  };
}
