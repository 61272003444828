import angular from "angular";

import "oclazyload";
import HomeModule from "./modules/home/home.module";
import ComingSoonModule from "./modules/coming-soon/coming-soon.module";
import AuthModule from './modules/auth/auth.module';
import OAuthModule from './modules/oauth/oauth.module';
import AboutModule from './modules/about/about.module';
import CoverageModule from './modules/coverage/coverage.module';
import BicyclesModule from './modules/bicycles/bicycles.module';
// route config

export default angular
  .module("vt-public-app", [
    HomeModule.name,
    ComingSoonModule.name,
    AuthModule.name,
    OAuthModule.name,
    AboutModule.name,
    CoverageModule.name,
    BicyclesModule.name
  ]);
