import './bicycle-public-link.modal.scss';

export default function BicyclePublicLinkModal($scope, close, fakeId, Notification, $t) {
  'ngInject';

  $scope.bicycle = {
    link: document.location.origin + '/bicycle/public/' + fakeId
  };

  $scope.copy = () => {
    navigator.clipboard.writeText($scope.bicycle.link).then(() => {
      Notification.success($t('shared.modals.bicycle_public_link.copy_link_success_message'))
    }, (err) => {
      Notification.error(err)
    });
  }

  $scope.close = () => {
    close(false);
  }
}