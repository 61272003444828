import './main-menu-item.component.scss';

export default {
  template: require('./main-menu-item.component.html'),
  bindings: {
    href: "@?vuiHref",
    title: "@?vuiTitle"
  },
  transclude: true,
  controller: function () {
    'ngInject';
  }
};
