import './side-menu-sign-out-to-admin-item.component.scss';

export default {
  template: require('./side-menu-sign-out-to-admin-item.component.html'),
  bindings: {
    href: "@?vuiHref",
    segment: "@?vuiSegment"
  },
  transclude: true,
  controller: function (AuthService) {
    'ngInject';

    const self = this;

    self.signOut = () => {
      AuthService.logoutToAdmin('/admin/users');
    }
  }
};
