import "./landing-page-video.component.scss"

export default {
    template: require('./landing-page-video.component.html'),
    bindings: {
        title: "@lphTitle",
        subtitle: "@lphSubtitle"
    },
    transclude: true,
    controller: function () {
        'ngInject';
    }
};
