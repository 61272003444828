import './transfer-bicycle.modal.scss';

export default function TransferBicycleModal($scope, close) {
  'ngInject';

  $scope.transfer = {
    email: ''
  };

  $scope.ok = (form) => {
    if (form.$valid) {
      close($scope.transfer.email);
    }
  }

  $scope.close = () => {
    close(false);
  }
}