import angular from "angular";
import LoadingImage from "img/loading-bicycle.gif";

import './upload-images.modal.scss';

export default function UploadImagesModal($scope, $element, close, images, title, onUpload, FileService, $timeout, Notification, $t) {
  'ngInject';

  $scope.uploadImages = {
    images: structuredClone(images),
    title: title,
    photosListPosition: 1,
    photosTranslate: 'translateX(0px)',
    showImagesNavigations: false
  };

  $scope.chooseFile = function (files, invalidFiles) {
    if (!files || files.length === 0) {
      return;
    }

    const filesToUpload = [];
    files.filter(file => FileService.isValidFile(file))
      .forEach(file => filesToUpload.push(file));
    invalidFiles.filter(file => FileService.isValidFile(file))
      .forEach(file => filesToUpload.push(file));
    if (filesToUpload.length === 0) {
      return;
    }

    filesToUpload.forEach(file => {
      const img = {
        id: file.name,
        thumbnail: LoadingImage,
        original: file,
        uploading: true,
        error: true
      }

      $scope.uploadImages.images.push(img);
      $scope.upload($scope.uploadImages.images.length - 1)
    })

    findListElements();
  };

  $scope.upload = index => {
    const img = $scope.uploadImages.images[index];
    img.uploading = true;
    img.error = false;

    onUpload(img.original,
      response => {
        for (let i = 0; i < $scope.uploadImages.images.length; i++) {
          if ($scope.uploadImages.images[i].id === img.id) {
            $scope.uploadImages.images[i] = response;
            break;
          }
        }
      },
      error => {
        img.error = true;
        img.uploading = false;
        Notification.error(error.data.message || 'shared.modals.photos.upload_image_error')
      }
    );
  }

  $scope.removeImage = (index) => {
    if (index > -1 && index < $scope.uploadImages.images.length) {
      $scope.uploadImages.images.splice(index, 1)
    }
  }

  $scope.changeDefaultPhoto = (index) => {
    if (index > -1 && index < $scope.uploadImages.images.length) {
      const tmp = $scope.uploadImages.images[index];
      $scope.uploadImages.images[index] = $scope.uploadImages.images[0];
      $scope.uploadImages.images[0] = tmp;
    }
  }

  const findElemByClass = (className) => {
    const list = $element.find('div')
    for (let i = 0; i < list.length; i++) {
      if (list[i].className.indexOf(className) >= 0) {
        return angular.element(list[i]);
      }
    }
  }

  let $container;
  let $list;

  const findListElements = () => {
    $timeout(() => {
      if (!$container) {
        $container = findElemByClass('scrollable-list');
      }
      if (!$list) {
        $list = findElemByClass('body__list-photos');
      }

      $scope.uploadImages.showImagesNavigations = $container && $list
        && ($list[0].offsetWidth - $container[0].offsetWidth > 1);
      enableNavigations();
    })
  }

  const enableNavigations = () => {
    if ($container && $list) {
      const maxOffset = $container[0].offsetWidth - $list[0].offsetWidth;

      $scope.uploadImages.enabledPrev = $scope.uploadImages.photosListPosition < 0;
      $scope.uploadImages.enabledNext = $scope.uploadImages.photosListPosition > maxOffset;
    }
  }

  findListElements();

  $scope.showNextPhotos = (e) => {
    $scope.uploadImages.photosListPosition -= 212;
    const maxOffset = $container[0].offsetWidth - $list[0].offsetWidth;
    if ($scope.uploadImages.photosListPosition < maxOffset) {
      $scope.uploadImages.photosListPosition = maxOffset;
    }
    $scope.uploadImages.photosTranslate = 'translateX(' + $scope.uploadImages.photosListPosition + 'px)';
    enableNavigations();
  }

  $scope.showPrevPhotos = (e) => {
    $scope.uploadImages.photosListPosition += 212;
    if ($scope.uploadImages.photosListPosition > -1) {
      $scope.uploadImages.photosListPosition = -1;
    }
    $scope.uploadImages.photosTranslate = 'translateX(' + $scope.uploadImages.photosListPosition + 'px)';
    enableNavigations();
  }

  $scope.save = () => {
    const result = [];
    $scope.uploadImages.images.forEach(img => {
      if (!img.error && !img.uploading) {
        result.push(img);
      }
    })

    close(result);
  }

  $scope.close = () => {
    close(false);
  }
}