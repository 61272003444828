import './change-payment-source.modal.scss';

export default function ChangePaymentSourceModal($scope, close, sources, currentSource) {
  'ngInject';

  $scope.changePaymentSource = {
    sources: sources || [],
    currentSource: currentSource
  };

  $scope.close = () => {
    close();
  };

  $scope.ok = () => {
    close({source: $scope.changePaymentSource.currentSource});
  };

  $scope.addNewCard = () => {
    close({ new: true });
  };

}