export default function ($routeSegmentProvider) {

    "ngInject";

    $routeSegmentProvider
        .when('/bicycle/public/:id', 'public-bicycle-profile')

        .segment('public-bicycle-profile', {
            access: {requireLogin: false},
            template: '<public-bicycle-profile-page></public-bicycle-profile-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bicycles.module" */ "./public-profile/public-profile.module");
            }
        });
}
