import angular from "angular";
import Carousel from "../../../vt-generic/components/carousel";
import Typeform from "../../../vt-generic/components/type-form";
import WistiaVideo from "../../../vt-generic/components/wistia-video-player";
import Logo from "../../../vt-generic/components/logo/logo.module";
import HeaderItem from "../../../vt-generic/components/header-item/header-item.module";
import HeaderLink from "../../../vt-generic/components/header-link/header-link.module";

import LandingPageHeaderComponent from "./landing-page-header/landing-page-header.component";
import LandingPageHeroComponent from "./landing-page-hero/landing-page-hero.component";
import LandingPageMainActionsComponent from "./landing-page-main-actions/landing-page-main-actions.component";
import LandingPageVideoComponent from "./landing-page-video/landing-page-video.component";
import LandingPageInfoBlockComponent from "./landing-page-info-block/landing-page-info-block.component";
import LandingPageGridTwo from "./landing-page-grid-two/landing-page-grid-two.component";
import LandingPageGridThree from "./landing-page-grid-three/landing-page-grid-three.component";
import LandingPageTestimonials from "./landing-page-testimonials/landing-page-testimonials.component";
import LandingPageInfoSection from "./landing-page-info-section/landing-page-info-section.component";
import LandingPageUnderlinedHeader from "./landing-page-underlined-header/landing-page-underlined-header.component";
import LandingPageSection from "./landing-page-section/landing-page-section.component";
import LandingPageSectionContent from "./landing-page-section-content/landing-page-section-content.component";
import LandingPageServiceList from "./landing-page-service-list/landing-page-service-list.component";
import LandingPageCarousel from "./landing-page-carousel/landing-page-carousel.component";
import LandingPageCarouselItem from "./landing-page-carousel-item/landing-page-carousel-item.component";
import LandingPageInfoMessage from "./landing-page-info-message/landing-page-info-message.component";
import LandingPageArrowedList from "./landing-page-arrowed-list/landing-page-arrowed-list.component";
import LandingPageArrowedListItem from "./landing-page-arrowed-list-item/landing-page-arrowed-list-item.component";
import LandingPageScrollingList from "./landing-page-scrolling-list/landing-page-scrolling-list.component";
import LandingPageDescBlock from "./landing-page-desc-block/landing-page-desc-block.component";
import LandingPageSteps from "./landing-page-steps/landing-page-steps.component";
import LandingPageStepsItem from "./landing-page-steps-item/landing-page-steps-item.component";
import LandingPageAccordion from "./landing-page-accordion/landing-page-accordion.component";
import LandingPageAccordionItem from "./landing-page-accordion-item/landing-page-accordion-item.component";
import LandingPageMainBlock from "./landing-page-main-block/landing-page-main-block.component";
import LandingPageVtLogo from "./landing-page-vt-logo/landing-page-vt-logo.component";

import ServicesPublicApi from "../../resources/services/public-services.resource";

export default angular.module("vt-public-landing-page.module", [
    Carousel.name,
    Typeform.name,
    WistiaVideo.name,
    Logo.name,
    HeaderItem.name,
    HeaderLink.name,
    ServicesPublicApi.name
])
    .component("landingPageHeader", LandingPageHeaderComponent)
    .component("landingPageHero", LandingPageHeroComponent)
    .component("landingPageMainActions", LandingPageMainActionsComponent)
    .component("landingPageVideo", LandingPageVideoComponent)
    .component("landingPageInfoBlock", LandingPageInfoBlockComponent)
    .component("landingPageGridTwo", LandingPageGridTwo)
    .component("landingPageGridThree", LandingPageGridThree)
    .component("landingPageTestimonials", LandingPageTestimonials)
    .component("landingPageInfoSection", LandingPageInfoSection)
    .component("landingPageUnderlinedHeader", LandingPageUnderlinedHeader)
    .component("landingPageSection", LandingPageSection)
    .component("landingPageSectionContent", LandingPageSectionContent)
    .component("landingPageServiceList", LandingPageServiceList)
    .component("landingPageCarousel", LandingPageCarousel)
    .directive("landingPageCarouselItem", LandingPageCarouselItem)
    .component("landingPageInfoMessage", LandingPageInfoMessage)
    .component("landingPageArrowedList", LandingPageArrowedList)
    .component("landingPageArrowedListItem", LandingPageArrowedListItem)
    .component("landingPageScrollingList", LandingPageScrollingList)
    .component("landingPageDescBlock", LandingPageDescBlock)
    .component("landingPageSteps", LandingPageSteps)
    .component("landingPageStepsItem", LandingPageStepsItem)
    .component("landingPageAccordion", LandingPageAccordion)
    .component("landingPageAccordionItem", LandingPageAccordionItem)
    .component("landingPageMainBlock", LandingPageMainBlock)
    .component("landingPageVtLogo", LandingPageVtLogo);
