import angular from "angular";

export default ($timeout) => {

  'ngInject';

  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      const setValue = (element, value, scope, ctrl) => {
        element.val(value);
        ctrl.$setViewValue(value);

        $timeout(() => {
          element.val(value);
          scope.$apply();
        });
      };

      const formatZip = (e) => {
        const elm = angular.element(e.currentTarget || e.srcElement);
        let value = elm.val();
        
        if (!value || value === ' ') {
          setValue(elm, value, scope, ctrl);
          return;
        }

        value = value.replace(/^\s+/, '');
        /* Delete first spaces */
        value = value.replace(/\s(?=\s)/g, '');
        /* Delete double spaces */
        value = value.replace(/^-+/, '');
        /* Delete first hyphens */
        value = value.replace(/-(?=-)/g, '');
        /* Delete double hyphens */
        value = value.replace(/[^A-Za-z0-9-\s]/g, '').substr(0, 10).toUpperCase();

        const valid = (value.length > 3 && /\d/g.test(value));
        ctrl.$setValidity('zipCode', valid);

        setValue(elm, value, scope, ctrl);
        e.preventDefault();
      }

      element.on('input', formatZip);
      element.on('paste', formatZip);

      scope.$on('$destroy', () => {
        element.off('input', formatZip);
        element.off('paste', formatZip);
      });
    }
  };
}
