import './location-autocomplete.component.scss';

export default () => {
  return {
    restrict: 'E',
    scope: {
      ngModel: "=ngModel",
      resource: "<vuiResource",
      placeholder: "@vuiPlaceholder",
      required: "@vuiRequired",
      name: "@vuiName",
      showIcon: "=vuiShowIcon",
      onChange: "<?vuiChange"
    },
    template: require('./location-autocomplete.component.html'),
    link: (scope, element, attr) => {
      scope.addressFilter = '';
    },
    controller: ($scope, ErrorHandler, Notification, $q, $t) => {
      'ngInject';

      $scope.searchAddress = function (request) {
        const deferred = $q.defer();
        const query = {
          address: request
        };
        query.types = 'geocode';

        $scope.resource.autocomplete(query,
          data => {
            data.unshift({addressType: 'DETECT_CURRENT_LOCATION'});
            deferred.resolve(data);
          },
          error => {
            deferred.reject(error);
          });

        return deferred.promise;
      };

      $scope.displayItem = (item) => {
        if (item && item.addressType && 'DETECT_CURRENT_LOCATION' === item.addressType) {
          return " ";
        }
        if (item && !item.address) {
          return item;
        }
        if (item && item.address) {
          return item.address;
        }
        if ($scope.ngModel && $scope.ngModel.address) {
          return $scope.ngModel.address;
        }

        return null;
      }

      $scope.onItemChange = (selectedAddress) => {
        if (selectedAddress && 'DETECT_CURRENT_LOCATION' === selectedAddress.addressType) {
          detectCurrentLocation();
        } else if (selectedAddress && selectedAddress.placeId && !selectedAddress.bounds) {
          selectAddress(selectedAddress);
        } else {
          setModel(selectedAddress)
        }
      };

      const detectCurrentLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const request = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            try {
              $scope.resource.geocode(request, {},
                setModel,
                error => {
                  ErrorHandler.onError(error, $t('shared.location_autocomplete.detect_current_location_error'));
                });
            } finally {
              setModel();
            }
          }, (error) => {
            setModel();
            Notification.warning($t('shared.location_autocomplete.geolocation_not_supported_error'));
          });
        } else {
          setModel();
          Notification.warning($t('shared.location_autocomplete.geolocation_not_supported_error'));
        }
      };

      const selectAddress = (location) => {
        $scope.resource.details({id: location.placeId},
          l => {
            l.address = location.address;
            l.apartment = location.apartment;
            setModel(l);
          },
          error => {
            ErrorHandler.onError(error, $t('shared.location_autocomplete.detect_current_location_error'));
          });
      }

      const setModel = location => {
        $scope.ngModel = location;

        if ($scope.onChange) {
          $scope.onChange(location)
        }
      }
    }
  };
}
