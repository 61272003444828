import angular from "angular";

import './button.component.scss';

export default () => {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: (element, attr) => {
            if (angular.isDefined(attr.href) || angular.isDefined(attr.ngHref) || angular.isDefined(attr.ngLink) || angular.isDefined(attr.uiSref)) {
                return '<a class="vui-btn" ng-transclude></a>';
            } else {
                const btnType = (typeof attr.type === 'undefined') ? 'button' : attr.type;
                return '<button class="vui-btn" type="' + btnType + '" ng-transclude></button>';
            }
        },
        link: (scope, element, attr) => {
            element.on('click', function (e) {
                if (attr.disabled === true) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            });
        }
    };
}
