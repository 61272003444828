import app from "js/legacy-app";

app.service("RecaptchaService", [function () {
  return {
    execute: function (action, callback) {
      let recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;
      grecaptcha.execute(recaptchaSiteKey, {action: action})
        .then(callback);
    }
  };
}]);
