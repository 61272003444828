import angular from "angular";
import MarkerImage from "img/market-pin-dot.png";

import "./g-map.component.scss";

export default {
  template: require('./g-map.component.html'),
  bindings: {
    map: '=map'
  },
  controller: function ($timeout, $scope, $compile, $element, $routeParams, $t, gMapConfig) {
    'ngInject';

    const self = this;

    const init = () => {
      self.map.map = new window.google.maps.Map($element[0].children[0], gMapConfig);
    };

    let locations = [];
    let existingShapes = [];

    self.map.addMarker = (area, options) => {
      const defaultMarkerOptions = {
        position: area.center,
        map: self.map.map,
        icon: MarkerImage
      };

      options = options || {};

      const markerOptions = angular.extend({}, defaultMarkerOptions, options.marker || {});
      const marker = new window.google.maps.Marker(markerOptions);

      const location = {
        key: area,
        marker: marker
      };

      locations.push(location);

      return location;
    }

    self.map.clear = () => {
      locations.filter(location => location.infoWindow)
        .forEach(location => location.infoWindow.close());
      locations
        .map(location => location.marker)
        .forEach(marker => marker.setMap(null));
      locations = [];

      existingShapes.forEach(shape => shape.setMap(null));
      existingShapes = [];
    }

    self.map.addBicycleInfoWindow = (location, options) => {
      if (options.infoWindow) {
        locations.filter(location => location.infoWindow)
          .forEach(location => location.infoWindow.close());

        const template =
          '<div class="vui-g-map__location-info">' +
          '   <div class="location-info__icon">' +
          '     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '       <path d="M14 6.66699C14 11.3337 8 15.3337 8 15.3337C8 15.3337 2 11.3337 2 6.66699C2 5.07569 2.63214 3.54957 3.75736 2.42435C4.88258 1.29913 6.4087 0.666992 8 0.666992C9.5913 0.666992 11.1174 1.29913 12.2426 2.42435C13.3679 3.54957 14 5.07569 14 6.66699Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>' +
          '       <path d="M8 8.66699C9.10457 8.66699 10 7.77156 10 6.66699C10 5.56242 9.10457 4.66699 8 4.66699C6.89543 4.66699 6 5.56242 6 6.66699C6 7.77156 6.89543 8.66699 8 8.66699Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>' +
          '     </svg>' +
          '   </div>' +
          '   <div class="location-info__content">' +
          options.infoWindow +
          '   </div>' +
          '</div>'

        const currentInfoWindow = new window.google.maps.InfoWindow({
          content: template,
          position: new google.maps.LatLng(location.marker.position.lat(), location.marker.position.lng())
        });
        location.infoWindow = currentInfoWindow;

        currentInfoWindow.open(self.map.map);
      }
    }

    self.map.addServiceProviderInfoWindow = (location, options) => {
      location.serviceProvider = options.serviceProvider;
      location.openCallback = options.openCallback;

      const template =
        '<div class="vui-g-map__service-provider-info">' +
        '  <div class="service-provider__avatar">' +
        '    <img src="{{serviceProvider.avatar}}" alt="Service Provider">' +
        '  </div>' +
        '  <div class="service-provider__content">' +
        '    <div class="title">' +
        '      <div class="title__name">' +
        '        {{serviceProvider.name}} {{serviceProvider.surname}}' +
        '      </div>' +
        '      <div class="title__rating">' +
        '        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '          <path d="M8 1.30902L9.55835 6.10512L9.61448 6.27786H9.79611H14.839L10.7592 9.24202L10.6123 9.34878L10.6684 9.52153L12.2268 14.3176L8.14695 11.3535L8 11.2467L7.85305 11.3535L3.77325 14.3176L5.33159 9.52153L5.38772 9.34878L5.24078 9.24202L1.16097 6.27786H6.20389H6.38552L6.44165 6.10512L8 1.30902Z" fill="#FFF400" stroke="#B3B3B3" stroke-width="0.5"/>' +
        '        </svg>' +
        '        {{serviceProvider.rating}}' +
        '      </div>' +
        '    </div>' +
        '    <div class="category">' +
        '      {{ "shared.service_provider_categories." + serviceProvider.mechanicCategory.toLowerCase() | translate }}' +
        '    </div>' +
        '    <div class="book-service">' +
        '      <vui-button class="btn-sm btn-sm-primary"' +
        '                   href="' + options.link + '">' +
        '           {{ "shared.service_providers.favorite.book_btn" | translate }}' +
        '       </vui-button>' +
        '    </div>' +
        '  </div>' +
        /*'  <div class="service-provider__more">' +
        '    <vui-more-button>' +
        '      <vui-button-content>' +
        '        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '          <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"' +
        '                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
        '          <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"' +
        '                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
        '          <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"' +
        '                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' +
        '        </svg>' +
        '      </vui-button-content>' +
        '      <vui-more-actions>' +
        '        <vui-more-action ng-click="$ctrl.bookService()">' +
        '          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '            <path d="M13.3337 14V12.6667C13.3337 11.9594 13.0527 11.2811 12.5526 10.781C12.0525 10.281 11.3742 10 10.667 10H5.33366C4.62641 10 3.94814 10.281 3.44804 10.781C2.94794 11.2811 2.66699 11.9594 2.66699 12.6667V14M10.667 4.66667C10.667 6.13943 9.47308 7.33333 8.00033 7.33333C6.52757 7.33333 5.33366 6.13943 5.33366 4.66667C5.33366 3.19391 6.52757 2 8.00033 2C9.47308 2 10.667 3.19391 10.667 4.66667Z" stroke="#B3B3B3" stroke-linecap="round" stroke-linejoin="round"/>' +
        '          </svg>' +
        '          <span>' +
        '            {{ "shared.service_providers.favorite.show_service_provider_profile_btn" | translate }}' +
        '          </span>' +
        '        </vui-more-action>' +
        '        <vui-more-action ng-click="$ctrl.bookService()">' +
        '          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '            <path d="M13.8931 3.07333C13.5526 2.73267 13.1483 2.46243 12.7033 2.27805C12.2584 2.09368 11.7814 1.99878 11.2998 1.99878C10.8181 1.99878 10.3412 2.09368 9.89618 2.27805C9.45121 2.46243 9.04693 2.73267 8.70642 3.07333L7.99975 3.78L7.29309 3.07333C6.60529 2.38553 5.67244 1.99914 4.69975 1.99914C3.72707 1.99914 2.79422 2.38553 2.10642 3.07333C1.41863 3.76112 1.03223 4.69397 1.03223 5.66666C1.03223 6.63935 1.41863 7.5722 2.10642 8.26L2.81309 8.96666L7.99975 14.1533L13.1864 8.96666L13.8931 8.26C14.2338 7.91949 14.504 7.51521 14.6884 7.07023C14.8727 6.62526 14.9676 6.14832 14.9676 5.66666C14.9676 5.185 14.8727 4.70807 14.6884 4.26309C14.504 3.81812 14.2338 3.41383 13.8931 3.07333V3.07333Z" stroke="#B3B3B3" stroke-linecap="round" stroke-linejoin="round"/>' +
        '          </svg>' +
        '          <span>' +
        '            {{ "shared.service_providers.favorite.add_service_provider_to_favorite" | translate }}' +
        '          </span>' +
        '        </vui-more-action>' +
        '        <vui-more-action ng-click="$ctrl.bookService()">' +
        '          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '            <path d="M13.8931 3.07333C13.5526 2.73267 13.1483 2.46243 12.7033 2.27805C12.2584 2.09368 11.7814 1.99878 11.2998 1.99878C10.8181 1.99878 10.3412 2.09368 9.89618 2.27805C9.45121 2.46243 9.04693 2.73267 8.70642 3.07333L7.99975 3.78L7.29309 3.07333C6.60529 2.38553 5.67244 1.99914 4.69975 1.99914C3.72707 1.99914 2.79422 2.38553 2.10642 3.07333C1.41863 3.76112 1.03223 4.69397 1.03223 5.66666C1.03223 6.63935 1.41863 7.5722 2.10642 8.26L2.81309 8.96666L7.99975 14.1533L13.1864 8.96666L13.8931 8.26C14.2338 7.91949 14.504 7.51521 14.6884 7.07023C14.8727 6.62526 14.9676 6.14832 14.9676 5.66666C14.9676 5.185 14.8727 4.70807 14.6884 4.26309C14.504 3.81812 14.2338 3.41383 13.8931 3.07333V3.07333Z" stroke="#B3B3B3" stroke-linecap="round" stroke-linejoin="round"/>' +
        '          </svg>' +
        '          <span>' +
        '            {{ "shared.service_providers.favorite.remove_service_provider_from_favorite" | translate }}' +
        '          </span>' +
        '        </vui-more-action>' +
        '      </vui-more-actions>' +
        '    </vui-more-button>' +
        '  </div>' +*/
        '</div>';

      const infoWindowScope = $scope.$new();
      infoWindowScope.serviceProvider = options.serviceProvider;
      const linkFn = $compile(template);
      const infoWindow = linkFn(infoWindowScope);

      const marker = location.marker;

      const currentInfoWindow = new window.google.maps.InfoWindow({
        content: infoWindow[0],
        position: new google.maps.LatLng(marker.position.lat(), marker.position.lng())
      });
      location.infoWindow = currentInfoWindow;

      marker.addListener('click', () => {
        locations.forEach(location => {
          location.infoWindow.close();
          location.openCallback(false);
        });
        location.openCallback(true);
        currentInfoWindow.open(self.map.map);
      });
    }

    self.map.center = () => {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach(location => bounds.extend(location.marker.position));
      bounds.extend(new google.maps.LatLng(bounds.getSouthWest().lat() - 0.003, bounds.getSouthWest().lng() - .035));
      self.map.map.fitBounds(bounds);
      if (locations.length === 1) {
        self.map.map.setZoom(15);
      }
    }

    self.map.setLocation = (area, options) => {
      const location = self.map.addMarker(area, options);
      self.map.center();
      return location;
    };

    self.map.addCircle = (center, radius, options) => {
      const defaultOptions = {
        map: self.map.map,
        strokeColor: '#777777',
        fillColor: '#fff400',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.4
      };

      options = options || {};

      const circleOptions = angular.extend({}, defaultOptions, options || {});
      circleOptions.center = center;
      circleOptions.radius = radius;

      const circle = new window.google.maps.Circle(circleOptions);

      existingShapes.push(circle);

      return circle;
    };

    init();

  }
};
