import angular from "angular";

export default ($timeout, Payment) => {

  'ngInject';

  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      const setValue = (element, value, scope, ctrl) => {
        element.val(value);
        ctrl.$setViewValue(value);

        $timeout(() => {
          element.val(value);
          scope.$apply();
        });
      }

      const formatCardName = (e) => {
        const element = angular.element(e.currentTarget || e.srcElement);
        let value = element.val();
        if (!value || value === ' ') {
          setValue(element, '', scope, ctrl);
          return;
        }

        value = Payment.formatCardName(value);
        const valid = Payment.validateCardName(value);
        ctrl.$setValidity('fullName', valid);

        setValue(element, value, scope, ctrl);

        e.preventDefault();
      }

      element.on('input', formatCardName);
      element.on('paste', formatCardName);

      scope.$on('$destroy', () => {
        element.off('input', formatCardName);
        element.off('paste', formatCardName);
      });
    }
  };
}
