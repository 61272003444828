import "./form-info.component.scss";

export default {
  template: require('./form-info.component.html'),
  bindings: {
    size: "@?vuiSize",
    images: "=?vuiImages",
  },
  transclude: {
    title: "?vuiFormInfoTitle",
    mainInfo: "?vuiFormMainInfo",
    additionInfo: "?vuiFormAdditionInfo"
  },
  controller: function () {
    'ngInject';
  }
};
