import './side-form.component.scss';

export default {
  template: require('./side-form.component.html'),
  bindings: {
    id: '@vuiId'
  },
  transclude: true,
  controller: function ($scope, $timeout, $element, vuiSideForm) {
    'ngInject';

    const $form = $element.children();

    $scope.open = () => {
      $form.removeClass('vui-side-form-container__hide');
      $form.addClass('vui-side-form-container__hide--animated');
      $timeout(() => {
        $form.removeClass('vui-side-form-container__hide--animated');
      });
    };

    $scope.close = () => {
      $form.addClass('vui-side-form-container__hide--animated');
      $timeout(() => {
        $form.addClass('vui-side-form-container__hide');
        $form.removeClass('vui-side-form-container__hide--animated');
      }, 250);
    };

    vuiSideForm.register($scope.$ctrl.id, $scope);

    $scope.$on('$destroy', () => {
      vuiSideForm.unregister($scope.$ctrl.id);
    });
  }
};
