import angular from "angular";

import VtSharedModule from '../../../vt-shared/vt-shared.module';
import BicycleOwnerModuleRouting from './bicycle-owner-root.module.routing';
import BicycleOwnerRootComponent from "./bicycle-owner-root.component";

export default angular.module("vt-bicycle-owner-root.module", [
  VtSharedModule.name,
])
  .config(BicycleOwnerModuleRouting)
  .component("bicycleOwnerPage", BicycleOwnerRootComponent);
