import DefaultBicycleImage from "img/ic_no_bicycle.svg";

import './add-bicycle.modal.scss';

export default function AddBicycleModal($scope, $q, close, types, loadBrands, Notification, $t) {
  'ngInject';

  $scope.addBicycle = {
    bicycle: {},
    types: types,
    brandFilter: ''
  };

  $scope.searchBrand = (request) => {
    const deferred = $q.defer();
    const query = {
      name: request,
      page: 0,
      size: 10
    };
    query.types = 'geocode';

    loadBrands(query,
      response => deferred.resolve(response),
      error => deferred.reject(error));

    return deferred.promise;
  }

  $scope.displayBrand = item => item ? item.name : ''

  $scope.ok = (form) => {
    if (form.$valid) {
      if (!$scope.addBicycle.bicycle.type) {
        Notification.error($t('shared.bicycles.update_bicycle.type_required_error'))
        return;
      }

      const bike = {
        serialNumber: $scope.addBicycle.bicycle.serialNumber,
        type: $scope.addBicycle.bicycle.type.name,
        make: $scope.addBicycle.bicycle.brand && $scope.addBicycle.bicycle.brand.name
          ? $scope.addBicycle.bicycle.brand.name : $scope.addBicycle.brandFilter,
        model: $scope.addBicycle.bicycle.model,
        isNew: true,
        image: {thumbnail: DefaultBicycleImage}
      }

      close(bike);
    }
  };

  $scope.close = () => {
    close();
  };

}