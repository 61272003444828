import angular from "angular";

export default ($timeout, $parse, Payment) => {

  'ngInject';

  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      const setValue = (element, value, scope, ctrl) => {
        element.val(value);
        ctrl.$setViewValue(value);

        $timeout(() => {
          element.val(value);
          scope.$apply();
        });
      }

      const cardType = $parse(attrs.vuiCardType);

      const applyCardType = (value) => {
        if (attrs.cardType) {
          const card = Payment.cardFromNumber(value);
          cardType.assign(scope, (card && cardType !== card.type) ? card.type : null);
        }
      }

      const formatCardNumber = (e) => {

        const elm = angular.element(e.currentTarget || e.srcElement);
        let value = elm.val();

        if (!value || value === ' ') {
          setValue(elm, '', scope, ctrl);
          return;
        }

        value = Payment.formatCardNumber(value);

        const valid = Payment.validateCardNumber(value);
        ctrl.$setValidity('substr', valid);

        ctrl.$formatters.unshift((value) => {
          applyCardType(value);
          return Payment.formatCardNumber(value);
        });

        ctrl.$parsers.unshift((value) => {
          applyCardType(value);
          return value;
        });

        const card = Payment.cardFromNumber(value);
        const testValue = value.replace(/\D/g, '');

        if (!valid && card && card.type && card.type === 'maestro' && testValue.length >= 12 && testValue.length <= 19) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'mastercard' && testValue.length === 16) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'amex' && testValue.length === 15) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'visa' && testValue.length >= 13 && testValue.length <= 16) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'visaelectron' && testValue.length === 16) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'discover' && testValue.length === 16) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'dinersclub' && testValue.length === 14) {
          ctrl.invalid = true;
        } else if (!valid && card && card.type && card.type === 'jcb' && (testValue.length === 15 || testValue.length === 16)) {
          ctrl.invalid = true;
        } else if (!card && testValue.length > 6) {
          ctrl.invalidCardType = true;
        } else {
          ctrl.invalid = false;
          ctrl.invalidCardType = false;
        }

        setValue(elm, value, scope, ctrl);

        e.preventDefault();
      }

      element.on('input', formatCardNumber);
      element.on('paste', formatCardNumber);

      scope.$on('$destroy', () => {
        element.off('input', formatCardNumber);
        element.off('paste', formatCardNumber);
      });
    }
  };
}
