import app from "js/legacy-app";

app.controller('ShowOrganisationRequestsHistoryCtrl', ['$scope', '$routeParams', '$http', function ($scope, $routeParams, $http) {

    $scope.organisationId = $routeParams.id;

    $scope.exportCSV = function (data, action) {
                $http.get('/api/organization-request/history/export/csv', {responseType: 'arraybuffer'})
                    .success(function (response, status, header) {
                        let file = new Blob([(response)]);
                        let url = URL.createObjectURL(file);
                        let anchor = document.createElement('a');
                        anchor.setAttribute('href', url);
                        anchor.setAttribute('download', header().filename);
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
            };

}]);
