import angular from "angular";

import './color-picker.component.scss';

export default () => {
  'ngInject';

  return {
    restrict: 'E',
    replace: true,
    require: ['ngModel'],
    scope: {
      ngModel: "=ngModel",
      disabled: "@disabled",
      onChange: '=vuiOnChange',
      config: '=vuiConfig',
    },
    transclude: true,
    template: require('./color-picker.component.html'),
    link: (scope, element) => {

      scope.cp = {
        chooseColor: []
      };
      scope.cp.colors = [
        {name: 'Black',       color: '#000000', id: 'black'},
        {name: 'Dark Blue',   color: '#000094', id: 'dark-blue'},
        {name: 'Blue',        color: '#1819E4', id: 'blue'},
        {name: 'Light Blue',  color: '#44EBFF', id: 'light-blue'},
        {name: 'Dark Red',    color: '#9C0000', id: 'dark-red'},
        {name: 'Red',         color: '#DF1D1D', id: 'red'},
        {name: 'Orange',      color: '#FF7439', id: 'orange'},
        {name: 'Yellow',      color: '#FFF400', id: 'yellow'},
        {name: 'Purple',      color: '#900C8E', id: 'purple'},
        {name: 'Pink',        color: '#F92FF8', id: 'pink'},
        {name: 'Green',       color: '#019902', id: 'green'},
        {name: 'Light Green', color: '#2DF32E', id: 'light-green'},
        {name: 'Grey',        color: '#B8B8B8', id: 'grey'},
        {name: 'White',       color: '#FFFFFF', id: 'white'},
        {name: 'Brown',       color: '#944900', id: 'brown'},
        {name: 'Carbon',      color: '#B8B8B8', id: 'carbon'}
      ];

      scope.cp.isActive = function(color) {
        if (!scope.cp.chooseColor) {
          return '';
        }
        return scope.cp.chooseColor.indexOf(color.id) > -1 ? 'active' : '';
      };

      scope.chooseColors = color => {
        scope.cp.changed = true;

        if (scope.cp.chooseColor.indexOf(color) > -1) {
          scope.cp.chooseColor.splice(scope.cp.chooseColor.indexOf(color), 1);
          return;
        }

        while (scope.cp.chooseColor.length === 3) {
          scope.cp.chooseColor.splice(0, 1);
        }

        scope.cp.chooseColor.push(color);
      }

      const findElemByClass = (className) => {
        const list = element.find('div')
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            return angular.element(list[i]);
          }
        }
      }

      const findElemsByClass = (className) => {
        const list = element.find('div')
        const result = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            result.push(angular.element(list[i]));
          }
        }

        return result;
      }

      const dropdown = findElemByClass('vui-color-picker__dropdown');

      scope.open = (e) => {
        if (scope.disabled === 'disabled') {
          return;
        }

        scope.cp.changed = false;
        if (scope.ngModel) {
          scope.cp.chooseColor = structuredClone(scope.ngModel);
        } else {
          scope.cp.chooseColor = [];
        }

        element.addClass('vui-input-container--opened');
        const rect = element[0].getClientRects()[0];

        const marginTop = calculatePosition(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
        dropdown.css('margin-top', marginTop + 'px');

        const activeItems = findElemsByClass('active');
        for (const item of activeItems) {
          item[0].scrollIntoView({block: "nearest", inline: "nearest"});
        }
      }

      if (scope.config) {
        scope.config.open = scope.open;
      }

      const calculatePosition = (isBottom, dropdown) => {
        const scrollTop = document.getElementsByClassName('vui-content')[0].scrollTop
        return -20 - scrollTop;
      }

      scope.close = () => {
        element.removeClass('vui-input-container--opened')
        element.focus();
      }

      scope.save = () => {
        element.removeClass('vui-input-container--opened')
        element.focus();

        if (scope.cp.changed) {
          scope.ngModel = scope.cp.chooseColor;

          if (scope.onChange) {
            scope.onChange(scope.ngModel);
          }
        }
      }
    }
  };
}
