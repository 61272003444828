import './stepper-item.component.scss';

export default {
  template: require('./stepper-item.component.html'),
  transclude: {
    label: "?vuiStepLabel",
    icon: "?vuiStepIcon"
  },
  controller: function () {
    'ngInject';
  }
};
