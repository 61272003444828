import './stepper-content.component.scss';

export default {
  template: require('./stepper-content.component.html'),
  transclude: true,
  controller: function () {
    'ngInject';

  }
};
