import './crop-image.modal.scss';

export default function CropImageModal($scope, close, source, title, onUpload, ToggleLD,
                                       FileService, AuthService, Notification, $t) {
  'ngInject';

  $scope.cropper = {
    area: {},
    sourceImage: source,
    croppedImage: null,
    title: title
  };

  $scope.upload = () => {
    if (!$scope.cropper.sourceImage || !$scope.cropper.croppedImage) {
      return;
    }

    const image = $scope.cropper.sourceImage;
    if (!FileService.isValidFile(image, [FileService.mimeImages])) {
      return;
    }

    ToggleLD.show();
    const cropOptions = {
      x: $scope.cropper.area.cropImageLeft,
      y: $scope.cropper.area.cropImageTop,
      w: $scope.cropper.area.cropImageWidth,
      h: $scope.cropper.area.cropImageHeight
    };

    onUpload($scope.cropper.sourceImage, cropOptions,
      (response) => {
        ToggleLD.hide();
        AuthService.user().avatar = response.avatar;
        close(response);
      },
      (error) => {
        ToggleLD.hide();
        Notification.error(error.data.message || $t('shared.modals.crop_image.upload_picture_error'));
      }
    );
  };

  $scope.close = () => {
    close(false);
  }
}