import './set-mechanic-rating.modal.scss';

export default function SetMechanicRatingModal($scope, close, $t, request) {
  'ngInject';

  $scope.ratingModal = {
    rating: 5,
    request: request
  };

  $scope.ok = (form) => {
    if (form.$valid) {
      const result = {
        unsatisfied: false,
        rating: $scope.ratingModal.rating,
        comment: $scope.ratingModal.comment
      };

      close(result);
    }
  }

  $scope.unsatisfied = () => {
    const result = {
      unsatisfied: true
    }

    close(result);
  };

}