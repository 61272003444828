import './option.component.scss';

export default () => {
    return {
        restrict: 'E',
        scope: {
            value: "=vuiValue"
        },
        transclude: true,
        template: require('./option.component.html'),
        link: (scope, element, attr) => {
            element.on('click', (e) => {
                scope.$apply(function () {
                    if (scope.$parent.$parent.$parent.setModel) {
                        scope.$parent.$parent.$parent.setModel(scope.value);
                    } else if (scope.$parent.$parent.$parent.$parent.setModel) {
                        scope.$parent.$parent.$parent.$parent.setModel(scope.value);
                    }
                });
            })

            element.on('keydown', function (e) {
                const keyCode = e.which || e.keyCode;
                if (keyCode === 13) {
                    scope.$parent.$parent.$parent.setModel(scope.value);
                } else if (keyCode === 40 && e.currentTarget.nextElementSibling) {
                    e.currentTarget.nextElementSibling.children[0].focus();
                    e.preventDefault();
                } else if (keyCode === 38 && e.currentTarget.previousElementSibling) {
                    e.currentTarget.previousElementSibling.children[0].focus();
                    e.preventDefault();
                } else if (keyCode === 27) {
                    scope.$parent.$parent.$parent.close();
                }
            });
        }
    };
}
