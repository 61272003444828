import "./form.component.scss";

export default {
  template: require('./form.component.html'),
  bindings: {
    submit: "=vuiSubmit",
    size: "@?vuiSize",
    back: "@?vuiBack",
    vuiShowTitle: "=vuiShowTitle",
    formName: "@vuiFormName"
  },
  transclude: {
    title: "?vuiFormTitle",
    body: "?vuiFormBody",
    actions: "?vuiFormActions"
  },
  controller: function ($scope, $timeout) {
    'ngInject';

    const self = this;

    const size = self.size ? self.size : 'md';
    self.formSize = 'vui-form-' + size;
    self.showTitle = self.vuiShowTitle !== false;

    $timeout(() => {
      $scope.$parent.$ctrl[self.formName] = $scope.$parent[self.formName] = $scope[self.formName];
    });

    if (self.back) {
      self.formSize += " vui-form__with-back"
    }
  }
};
