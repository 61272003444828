export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/profile/personal-info', 'bicycle-owner.personal-info')
    .when('/bicycle-owner/profile/payments', 'bicycle-owner.payments')
    .when('/bicycle-owner/profile/transactions', 'bicycle-owner.transactions')
    .when('/bicycle-owner/profile/transactions/:id', 'bicycle-owner.transaction-info')

    .within('bicycle-owner')

    .segment('personal-info', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'Profile',
        url: '/bicycle-owner/profile/personal-info'
      },
      template: '<bicycle-owner-personal-info-page></bicycle-owner-personal-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-personal-info.module" */ "./personal-info/bicycle-owner-personal-info.module");
      }
    })

    .segment('payments', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'Payment',
        url: '/bicycle-owner/profile/payments'
      },
      template: '<bicycle-owner-payments-page></bicycle-owner-payments-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-payments.module" */ "./payments/bicycle-owner-payments.module");
      }
    })

    .segment('transactions', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'All Transactions',
        url: '/bicycle-owner/profile/transactions'
      },
      template: '<bicycle-owner-transactions-page></bicycle-owner-transactions-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-transactions.module" */ "./transactions/bicycle-owner-transactions.module");
      }
    })

    .segment('transaction-info', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'Transaction details'
      },
      template: '<bicycle-owner-transaction-info-page></bicycle-owner-transaction-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-transaction-info.module" */ "./transaction-info/bicycle-owner-transaction-info.module");
      }
    });
}
