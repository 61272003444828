import angular from "angular";

export default ($timeout) => {

  'ngInject';

  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      const year = new Date().getFullYear().toString();
      const minDate = new Date().getTime();

      const formatDate = (val) => {
        if (val.length > 2) {
          const mm = val.substr(0, 2);
          const yy = val.substr(2, val.length);
          val = mm + " / " + yy;
        }

        return val;
      }

      const setValue = (element, value, scope, ctrl) => {
        element.val(value);
        ctrl.$setViewValue(value);

        $timeout(() => {
          element.val(value);
          scope.$apply();
        });
      }

      const formatExpiryDate = (e) => {

        let skipTest = false;

        ctrl.monthInvalid = false;
        ctrl.yearInvalid = false;
        ctrl.expired = false;

        const elm = angular.element(e.currentTarget || e.srcElement);
        let val = elm.val();

        val = val.replace(/\D/g, '');

        if (!val || val === ' ') {
          setValue(elm, val, scope, ctrl);
          return;
        }

        if (val.length === 1) {
          if (val !== '0' && val !== '1') {
            val = '0' + val;
          }
        }

        if (val.length === 2 && (parseInt(val) > 12 || val === '00')) {
          val = val.substr(0, 1);
        } else {
          if (val.length === 3) {
            const thirdNum = parseInt(val.charAt(2));
            const thirdNumYear = parseInt(year.charAt(2));
            if (thirdNum > thirdNumYear + 1 || thirdNum < thirdNumYear)
              val = val.substr(0, 2);
          }

          if (val.length === 4) {
            if (parseInt(val.charAt(2) + val.charAt(3)) > (parseInt(year.charAt(2) + year.charAt(3)) + 10)) {
              val = val.substr(0, 3);
            }

            if (parseInt(val.charAt(2) + val.charAt(3)) < parseInt(year.charAt(2) + year.charAt(3))) {
              val = val.substr(0, 3);
            }

            const minMonth = parseInt(val.substr(0, 2)) === 12 ? '1' : parseInt(val.substr(0, 2)) + 1;
            const minYear = parseInt(val.substr(0, 2)) === 12
              ? parseInt(year.substr(0, 2) + val.charAt(2) + val.charAt(3)) + 1
              : year.substr(0, 2) + val.charAt(2) + val.charAt(3);

            if (val.length === 4 && (minDate > new Date(minYear + '-' + minMonth).getTime())) {
              ctrl.$setValidity('cardExpiry', false);
              ctrl.expired = true;
              skipTest = true;
            }
          }
        }

        if (!skipTest) {
          const monthValid = new RegExp("^((0|1)$|(0[1-9]|1[0-2]))").test(val);
          const yearValid = (val.length === 3
            ? val.charAt(2) === (parseInt(year.charAt(2)) + '') || val.charAt(2) === (parseInt(year.charAt(2)) + 1 + '')
            : true);

          if (!monthValid) {
            ctrl.monthInvalid = true;
          }

          if (!yearValid) {
            ctrl.yearInvalid = true;
          }

          ctrl.$setValidity('cardExpiry', (monthValid && yearValid && val.length === 4));
        }

        val = formatDate(val);

        setValue(elm, val, scope, ctrl);

        e.preventDefault();
      };

      const onPastExpiryDate = (e) => {
        e.preventDefault();
      };

      element.on('input', formatExpiryDate);
      element.on('paste', onPastExpiryDate);

      scope.$on('$destroy', () => {
        element.off('input', formatExpiryDate);
        element.off('paste', onPastExpiryDate);
      });
    }
  };
}
