export default function SideFormServiceProvider() {
  this.$get = () => {
    'ngInject';

    function SideFormService() {

      const self = this;
      const forms = {};

      self.register = (id, form) => {
        forms[id] = form;
      }

      self.unregister = (id, form) => {
        delete forms[id];
      }

      self.open = (id) => {
        forms[id].open();
      }

      self.close = id => {
        forms[id].close();
      }
    }

    return new SideFormService();
  };
}