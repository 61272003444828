export default function () {
    'ngInject';

    return dirtyString => {
        if (!dirtyString) {
            return;
        }

        dirtyString = dirtyString.replace(/<script>(.*?)<\/script>/i, '');
        dirtyString = dirtyString.replace(/src*=*\\'(.*?)\\'/im, '');
        dirtyString = dirtyString.replace(/src*=*\\"(.*?)\\"/im, '');
        dirtyString = dirtyString.replace(/<\/script>/i, '');
        dirtyString = dirtyString.replace(/<script(.*?)>/im, '');
        dirtyString = dirtyString.replace(/eval\((.*?)\)/im, '');
        dirtyString = dirtyString.replace(/expression\((.*?)\)/im, '');
        dirtyString = dirtyString.replace(/javascript:/i, '');
        dirtyString = dirtyString.replace(/vbscript:/i, '');
        dirtyString = dirtyString.replace(/onload(.*?)=/im, '');
        return dirtyString;
    };
};
