import './page-size.component.scss';

export default {
  template: require('./page-size.component.html'),
  bindings: {
    pageSize: "=vuiPageSize",
  },
  controller: function ($location) {
    'ngInject';

    const self = this;

    self.pageSizes = [10, 25, 50, 100];

    self.onChange = (newValue) => {
      $location.search('size', newValue);
      $location.search('page', 0);
    }
  }
};
