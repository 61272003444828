export default function PaymentSourceServiceProvider() {
  this.$get = () => {
    'ngInject';

    function PaymentSourceService() {

      const self = this;

      const BRANDS = {
        'visa': {icon: require('img/payment/brands/visa.svg'), label: 'Visa'},
        'maestro': {icon: require('img/payment/brands/maestro.svg'), label: 'Maestro'},
        'visaelectron': {icon: require('img/payment/brands/visa.svg'), label: 'Visa Electron'},
        'visa electron': {icon: require('img/payment/brands/visa.svg'), label: 'Visa Electron'},
        'visamaster': {icon: require('img/payment/brands/visa.svg'), label: 'Visa Master'},
        'mastercard': {icon: require('img/payment/brands/mastercard.svg'), label: 'MasterCard'},
        'amex': {icon: require('img/payment/brands/amex.svg'), label: 'Amex'},
        'americanexpress': {icon: require('img/payment/brands/amex.svg'), label: 'Amex'},
        'american express': {icon: require('img/payment/brands/amex.svg'), label: 'Amex'},
        'bcglobal': {icon: require('img/payment/brands/amex.svg'), label: 'BCGlobal'},
        'discover': {icon: require('img/payment/brands/discover.svg'), label: 'Discover'},
        'dinersclub': {icon: require('img/payment/brands/diners-club.svg'), label: 'DinersClub'},
        'diners club': {icon: require('img/payment/brands/diners-club.svg'), label: 'DinersClub'},
        'jcb': {icon: require('img/payment/brands/jcb.svg'), label: 'JCB'},
        'carteblanche': {icon: require('img/payment/brands/stripe.svg'), label: 'CarteBlanche'},
        'instapayment': {icon: require('img/payment/brands/stripe.svg'), label: 'InstaPayment'},
        'koreanlocal': {icon: require('img/payment/brands/stripe.svg'), label: 'KoreanLocal'},
        'laser': {icon: require('img/payment/brands/stripe.svg'), label: 'Laser'},
        'solo': {icon: require('img/payment/brands/stripe.svg'), label: 'Solo'},
        'switch': {icon: require('img/payment/brands/stripe.svg'), label: 'Switch'},
        'unionpay': {icon: require('img/payment/brands/stripe.svg'), label: 'UnionPay'},
        'velotooler': {icon: require('img/payment/brands/velotooler.svg'), label: 'Velotooler'},
        'unknown': {icon: require('img/payment/brands/stripe.svg'), label: 'Unknown Brand'}
      }

      self.getDetails = brand => {
        if (brand) {
          return BRANDS[brand.toLowerCase()] || BRANDS['unknown'];
        } else {
          return BRANDS['unknown'];
        }
      }

      self.expiryDateFormat = date => {
        const expiryDate = date.split("-");
        return expiryDate[1] + '/' + expiryDate[0].replace('20', '');
      }

      self.parseCardNumber = details => {
        const result = {};
        result.last4 = details.replaceAll(' ', '').replaceAll('•', '');
        result.dots = []
        const dots = details.replace(result.last4, "");
        dots.split(' ')
          .filter(group => group !== '')
          .forEach(group => result.dots.push(group.split('')));
        return result;
      }

      self.prepareCardToView = item => {
        if (item && item.brand) {
          const cardDetails = self.getDetails(item.brand);
          item.icon = cardDetails.icon;
          item.label = cardDetails.label;

          if (item.brand.toLowerCase() === 'velotooler') {
            item.vtWallet = true;
          } else {
            const expiryDate = item.expiryDate.split("-");
            item.date = self.expiryDateFormat(item.expiryDate);

            const cardNumber = self.parseCardNumber(item.details);
            item.last4 = cardNumber.last4;
            item.dots = cardNumber.dots;
          }
        }
      }
    }

    return new PaymentSourceService();
  };
}