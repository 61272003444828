import angular from "angular";

export default function UploadServiceProvider() {
  this.$get = (Upload) => {
    'ngInject';

    function UploadService() {

      const self = this;

      const userAvatar = (url) => {
        return (image, cropArea, onSuccess, onError) => {
          return uploadImage(image, url, "POST", null, onSuccess, onError, null, {
            original: {
              quality: 0.8,
              width: 1000,
              height: 1000
            },
            thumbnail: {
              crop: cropArea
            }
          });
        };
      };

      const uploadBicycleImage = (url) => {
        return (image, onSuccess, onError, onProgress) => {
          const uploadRequest = {
            image: image
          };

          return uploadDocument(url, uploadRequest, "POST", onSuccess, onError, onProgress);
        };
      }

      const uploadRequestImage = (url) => {
        return (image, onSuccess, onError, onProgress) => {
          const uploadRequest = {
            image: image
          };

          return uploadDocument(url, uploadRequest, "POST", onSuccess, onError, onProgress);
        };
      }

      const uploadImage = (image, url, method, onResize, onSuccess, onError, onProgress, imageOptions) => {
        const img = new Image();
        img.onload = () => {
          imageOptions.original.width = Math.min(img.width, imageOptions.original.width);
          imageOptions.original.height = Math.min(img.height, imageOptions.original.height);
          Upload.resize(image, imageOptions.original)
            .then((resizedFile) => {
              if (onResize) {
                onResize(resizedFile);
              }
              const uploadRequest = {
                image: resizedFile
              };
              if (imageOptions.thumbnail && imageOptions.thumbnail.crop) {
                calculateCropArea(image, resizedFile, imageOptions.thumbnail, () => {
                  uploadRequest.thumbnail = angular.toJson(imageOptions.thumbnail);
                  uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
                });
              } else if (imageOptions.thumbnail) {
                uploadRequest.thumbnail = angular.toJson(imageOptions.thumbnail);
                uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
              } else {
                uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
              }
            });
        };

        img.onerror = () => {
          if (onError) {
            onError();
          }
        };

        img.src = URL.createObjectURL(image);
      };

      const calculateCropArea = (originalFile, resizedFile, thumbnailConfig, callback) => {
        getImageDimensions(originalFile, (oW, oH) => {
          getImageDimensions(resizedFile, (rW, rH) => {
            const wRatio = rW / oW;
            const hRatio = rH / oH;

            const cropArea = thumbnailConfig.crop;

            thumbnailConfig.crop = {
              x: Math.floor(cropArea.x * wRatio),
              y: Math.floor(cropArea.y * hRatio),
              w: Math.floor(cropArea.w * wRatio),
              h: Math.floor(cropArea.h * hRatio)
            };

            if (thumbnailConfig.width && thumbnailConfig.height) {
              thumbnailConfig.width = Math.min(thumbnailConfig.width, thumbnailConfig.crop.w);
              thumbnailConfig.height = Math.min(thumbnailConfig.height, thumbnailConfig.crop.h);

              const scaleX = thumbnailConfig.width / thumbnailConfig.crop.w;
              const scaleY = thumbnailConfig.height / thumbnailConfig.crop.h;

              thumbnailConfig.scale = Math.max(scaleX, scaleY);
            }

            callback(thumbnailConfig);
          });
        });
      };

      const getImageDimensions = (image, callback) => {
        const img = new Image();
        const url = URL.createObjectURL(image);

        img.onload = () => {
          callback(img.width, img.height);
        };

        img.src = url;
      };

      const uploadDocument = (url, data, method, onSuccess, onError, onProgress) => {
        if (!method) {
          method = 'POST';
        }

        Upload.upload({
          url: url,
          method: method,
          data: data
        }).success(response => {
          if (onSuccess) {
            onSuccess(response);
          }
        }).error(error => {
          if (onError) {
            onError(error);
          }
        }).progress(progress => {
          if (onProgress) {
            onProgress(progress);
          }
        });
      };

      self.bicycleOwner = () => {
        return {
          uploadAvatar: userAvatar('/api/v1/fo/bicycle-owner/profile/avatar'),
          uploadBicycleImage: uploadBicycleImage('/api/v1/fo/bicycle-owner/bicycles/images'),
          uploadRequestImage: uploadRequestImage('/api/v1/fo/bicycle-owner/requests/images')
        }
      }

    }

    return new UploadService();
  };
}