import angular from "angular";

import './multiselect-filter.component.scss';

export default () => {
    return {
        restrict: 'E',
        replace: true,
        require: ['ngModel'],
        scope: {
            ngModel: "=ngModel",
            items: "=vuiItems",
            placeholder: "@vuiPlaceholder",
            name: "@vuiName",
            required: "@vuiRequired",
            disabled: "@disabled",
            clazz: "@ngClass",
            onChange: '=vuiOnChange',
            mapValue: '=vuiMapValue'
        },
        template: require('./multiselect-filter.component.html'),
        link: (scope, element) => {

            scope.salt = new Date().getTime();

            const findElemByClass = (className) => {
                const list = element.find('div')
                for (let i = 0; i < list.length; i++) {
                    if (list[i].className.indexOf(className) >= 0) {
                        return angular.element(list[i]);
                    }
                }
            }

            const dropdown = findElemByClass('vui-select__dropdown')

            const open = () => {
                element.addClass('vui-input-container--opened');
                const rect = element[0].getClientRects()[0];

                const marginTop = calculateDropdownMargin(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
                dropdown.css('margin-top', marginTop + 'px');
                dropdown.css('width', rect.width + 'px');
            }

            const calculateDropdownMargin = (isBottom, dropdown) => {
                return isBottom ? -(dropdown.offsetHeight + element[0].offsetHeight + 8) : 8;
            }

            scope.setModel = (model) => {
                if (scope.ngModel === undefined) {
                    scope.ngModel = [];
                }

                if (scope.ngModel.includes(model)) {
                    scope.ngModel.splice(scope.ngModel.indexOf(model), 1)
                } else {
                    scope.ngModel.push(model);
                }

                if (scope.onChange) {
                    scope.onChange(scope.ngModel);
                }
            }

            scope.modelToString = (model) => {
                if (scope.mapValue) {
                    return scope.mapValue(model);
                } else if (model && typeof model === 'string') {
                    const s = model.toLowerCase();
                    return s.charAt(0).toUpperCase() + s.slice(1);
                } else if (model && model.length > 0) {
                    return model.join(',');
                }

                return undefined;
            }

            scope.toggle = () => {
                if (element.hasClass('vui-input-container--opened')) {
                    scope.close();
                } else {
                    open();
                }
            }

            scope.close = () => {
                element.removeClass('vui-input-container--opened')
            }

            const $input = element.find('input');
            $input.on('focusin', (e) => scope.toggle());
            $input.on('keydown', function (e) {
                const keyCode = e.which || e.keyCode;
                if (keyCode === 40) {
                    dropdown.find('div')[0].focus();
                    e.preventDefault();
                } else if (keyCode === 38) {
                    const options = dropdown.find('div');
                    options[options.length - 1].focus();
                    e.preventDefault();
                } else if (keyCode === 27) {
                    scope.close();
                } else {
                    e.preventDefault();
                }
            });
        }
    };
}
