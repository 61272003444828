export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner', 'bicycle-owner')

    .segment('bicycle-owner', {
      access: {requireLogin: true},
      template: '<bicycle-owner-page></bicycle-owner-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-root.module" */ "./bicycle-owner-root.module");
      }
    });
}
