import './more-action.component.scss';

export default () => {
  return {
    restrict: 'E',
    transclude: true,
    template: require('./more-action.component.html'),
    link: (scope, element, attr) => {
      element.on('click', (e) => {
        scope.$apply(function () {
          if (scope.$parent.$parent.close) {
            scope.$parent.$parent.close();
          } else if (scope.$parent.$parent.$parent.close) {
            scope.$parent.$parent.$parent.close();
          }
        });
      })
    }
  };
}
