import angular from "angular";

import './popover.component.scss';

export default ($timeout) => {
  'ngInject';

  return {
    restrict: 'E',
    replace: true,
    transclude: {
      element: "?vuiPopoverElement",
      content: "?vuiPopoverContent"
    },
    scope: {
      calculatePosition: "<?vuiCalculatePosition"
    },
    template: require('./popover.component.html'),
    link: (scope, element) => {

      const findElemByClass = (className) => {
        const list = element.find('div')
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            return angular.element(list[i]);
          }
        }
      }

      const findElemsByClass = (className) => {
        const list = element.find('div')
        const result = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            result.push(angular.element(list[i]));
          }
        }

        return result;
      }

      const calculatePosition = (isBottom, dropdown) => {
        if (scope.calculatePosition) {
          return scope.calculatePosition(isBottom, dropdown)
        }

        const scrollTop = document.getElementsByClassName('vui-content')[0].scrollTop
        if (document.body.offsetWidth > 767) {
          return (isBottom ? -(dropdown.offsetHeight + 90) : 8) - scrollTop;
        } else {
          return (isBottom ? -(dropdown.offsetHeight + 50) : 8) - scrollTop;
        }
      }

      const dropdown = findElemByClass('vui-popover__dropdown');

      scope.open = () => {
        element.addClass('vui-input-container--opening');
        element.addClass('vui-input-container--opened');
        $timeout(() => {
          element.removeClass('vui-input-container--opening');
        });
        const rect = element[0].getClientRects()[0];

        const marginTop = calculatePosition(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
        dropdown.css('margin-top', marginTop + 'px');

        const activeItems = findElemsByClass('active');
        for (const item of activeItems) {
          item[0].scrollIntoView({block: "nearest", inline: "nearest"});
        }
      }

      scope.close = () => {
        element.addClass('vui-input-container--opening');
        $timeout(() => {
          element.removeClass('vui-input-container--opening')
          element.removeClass('vui-input-container--opened')
        }, 250);
        element.focus();
      }
    }
  };
}
