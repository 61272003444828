import "./bicycle-owner-root.component.scss";

export default {
  template: require('./bicycle-owner-root.component.html'),
  controller: function (AuthService) {
    'ngInject';

    const self = this;

    self.auth = AuthService;
  }
};
