export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/settings', 'bicycle-owner.settings')

    .within('bicycle-owner')

    .segment('settings', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'Settings',
        url: '/bicycle-owner/settings'
      },
      template: '<bicycle-owner-security-page></bicycle-owner-security-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-security.module" */ "./security/bicycle-owner-security.module");
      }
    });
}
