import "./edit-btn.component.scss";

export default {
  template: require('./edit-btn.component.html'),
  transclude: true,
  bindings: {
    click: "=?vuiClick",
    disabled: "<?vuiDisabled"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.onClick = (e) => {
      if (!self.disabled && self.click) {
        self.click(e)
      }
    }
  }
};
