import './side-menu-item.component.scss';

export default {
  template: require('./side-menu-item.component.html'),
  bindings: {
    onClick: "=?vuiClick",
    href: "@?vuiHref",
    segment: "@?vuiSegment"
  },
  transclude: true,
  controller: function ($routeSegment) {
    'ngInject';

    const self = this;

    $routeSegment.chain.forEach(segment => {
      if (segment.name === self.segment) {
        self.isActive = true;
      }
    });
  }
};
