import './main-menu.component.scss';

export default {
  template: require('./main-menu.component.html'),
  transclude: true,
  bindings: {
    onOpen: "=vuiOpen"
  },
  controller: function () {
    'ngInject';
  }
};
