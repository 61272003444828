export default () => {

  'ngInject';

  return {
    require: ['^ngModel', '^form'],
    link: (scope, element, attrs, ctrls) => {

      const formController = ctrls[1];
      const ngModel = ctrls[0];
      const otherPasswordModel = formController[attrs.vuiPasswordMatchValidation];

      ngModel.$validators.passwordMatch = (modelValue) => {
        return (!modelValue && !otherPasswordModel.$viewValue) || (modelValue === otherPasswordModel.$viewValue);
      };

      otherPasswordModel.$parsers.push((value) => {
        ngModel.$setValidity('passwordMatch', (!value && !ngModel.$viewValue) || value === ngModel.$viewValue);
        return value;
      });
    }
  };
}
