export default function (vuiEmojis) {
  'ngInject';

  const rEmojis = new RegExp('<img .*? class="smile smile-(' + vuiEmojis.EMOJI_HEX.join('|') + ')".*?>', 'g');

  return input => {
    if (!input || !input.replace) {
      return input;
    }

    return input.replace(rEmojis, (match, text) => {
      return vuiEmojis.EMOJI_MAP[text];
    });
  };
}