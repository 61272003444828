export default function ResolutionServiceProvider() {
  this.$get = () => {
    'ngInject';

    function ResolutionService() {

      const self = this;

      const MOBILE_WIDTH = 767; //Max mobile width
      const TABLET_WIDTH = 991; //Max tablet width

      self.getScreenWidth = () => {
        return (window.innerWidth > 0) ? window.innerWidth : screen.width;
      }

      self.isMobile = () => {
        return self.getScreenWidth() < (MOBILE_WIDTH + 1);
      }

      self.isTablet = () => {
        return self.getScreenWidth() < (TABLET_WIDTH + 1);
      }

      self.isFullScreen = () => {
        return self.getScreenWidth() > TABLET_WIDTH;
      }
    }

    return new ResolutionService();
  };
}