import "./switch.component.scss";

export default {
  template: require('./switch.component.html'),
  transclude: true,
  bindings: {
    onChange: "=?vuiChange",
    ngModel: "=ngModel"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.onClick = () => {
      self.ngModel = !self.ngModel;
      if (self.onChange) {
        self.onChange(self.ngModel);
      }
    }
  }
};
