import './modal.component.scss';

export default {
  template: require('./modal.component.html'),
  bindings: {
    submit: "=vuiSubmit",
    formName: "@vuiFormName"
  },
  transclude: {
    modalTitle: "?vuiModalTitle",
    modalBody: "?vuiModalBody",
    modalActions: "?vuiModalActions"
  },
  controller: function ($scope, $timeout) {
    'ngInject';

    $timeout(() => {
      if ($scope.$ctrl.formName) {
        $scope.$parent[$scope.$ctrl.formName] = $scope[$scope.$ctrl.formName];
      }
    });
  }
};
